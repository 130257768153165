<template>
    <div class="sell-point">
        <b-card>
            <!-- {{ chapterDto.name }} -->
            <template #header>
                <!-- {{ id }} -->
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="users-alt"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات البحث</h4>
                </div>
            </template>
            <validationObserver ref="chapterForm">
                <b-row>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="اسم البحث"
                            label="اسم البحث"
                            v-model="chapterDto.name"
                            :rules="[
                                { type: 'required', message: 'اسم مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            type="number"
                            name="ترتيب البحث"
                            label="ترتيب البحث"
                            v-model="chapterDto.order"
                            :rules="[
                                { type: 'required', message: 'الترتيب مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-select
                            :options="unitsOptions"
                            v-model="chapterDto.unitId"
                            label="الوحدة"
                            name=" الوحدة"
                            :rules="[
                                { type: 'required', message: 'الوحدة مطلوبة' },
                            ]"
                        ></ek-input-select>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-select
                            v-model="chapterDto.teacherId"
                            :options="teachersList"
                            label="اسم استاذ"
                            name="اسم استاذ"
                            :rules="[
                                { type: 'required', message: 'الاستاذ مطلوب' },
                            ]"
                        ></ek-input-select>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="الفصل"
                            label="الفصل"
                            v-model="chapterDto.semester"
                            readonly
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="عدد الاكواد"
                            label="عدد الاكواد"
                            v-model="chapterDto.subCount"
                            readonly
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-select
                            v-model="chapterDto.subjectId"
                            label="المادة"
                            name=" المادة"
                            :options="subjectOptions"
                            disabled
                        ></ek-input-select>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="الدروس"
                            label="الدروس"
                            v-model="lessonNames"
                            readonly
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-date-picker
                            name="تاريخ البحث"
                            label="تاريخ البحث"
                            v-model="chapterDto.dateCreated"
                            readonly
                        ></ek-date-picker>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            type="number"
                            name="سعر البحث"
                            label="سعرّ البحث"
                            v-model="chapterDto.price"
                            :rules="[
                                { type: 'required', message: 'السعر مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <b-form-group label="حالة البحث">
                            <b-form-radio-group
                                id="radio-isHidden"
                                :options="options"
                                v-model="chapterDto.isFree"
                                name="حالة البحث"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validationObserver>
            <template #footer>
                <b-row>
                    <b-col lg="6">
                        <b-button
                            variant="main-color"
                            class="mr-1"
                            @click="submit()"
                            >تعديل</b-button
                        >
                        <b-button
                            variant="outline-primary"
                            @click="$router.go(-1)"
                            >تراجع</b-button
                        >
                    </b-col>
                    <b-col
                        lg="6"
                        class="d-flex justify-content-end align-items-center"
                    >
                        <b-button
                            variant="outline-primary"
                            @click="deleteChapter(id)"
                            >حذف</b-button
                        >
                    </b-col>
                </b-row>
            </template>
        </b-card>
        <ek-table
            :columns="columnsLesson"
            :items="chapterDto.lessons"
            @details="goToDetails"
            :no_select="true"
            :no_delete="true"
        >
            <template #table-header>
                <div class="d-flex p-1">
                    <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
                    <h4>الدروس</h4>
                </div>
            </template>
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    props: ["id"],
    components: {},
    data() {
        return {
            options: [
                { text: "مجاني", value: true },
                { text: "غير مجاني", value: false },
            ],
        };
    },
    computed: {
        ...mapState({
            chapterList: (state) => state.chapter.chapterList,
            chapterDto: (state) => state.chapter.dtoUpdateChapter,
            isFreeStatus: (state) => state.chapter.isFreeStatus,
            teachersList: ({ teachers }) => teachers.teachers,
            unitsOptions: (state) => state.units.unitsOptions,
            subjectOptions: ({ subjects }) => subjects.subjectOptions,
            columnsLesson: (state) => state.chapter.columnsLesson,
        }),
    },

    methods: {
        submit() {
            this.$refs["chapterForm"].validate().then((suc) => {
                if (suc) {
                    this.updateChapter(this.chapterDto);
                }
            });
        },
        deleteChapter(id) {
            this.deleteCapter(id);
        },

        ...mapActions([
            "getByIdChaptert",
            "deleteCapter",
            "updateChapter",
            "getTeachersList",
            "getSubjectOptions",
            "getSettingSemester",
        ]),
        goToDetails({ row }) {
            // this.$router.push("/admin/lessons");
             this.$router.push({
                path: `/admin/lessons/${row.id}`,
                query: { name: row.name, lessonType: row.lessonType },
            });
        },
    },

    created() {
        this.getByIdChaptert(this.id);
        this.getTeachersList();
        this.getSubjectOptions();
        this.getSettingSemester();
    },
};
</script>

<style lang="scss">
.sell-point {
    .card-header {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }
    .card-footer {
        border: none !important;
    }
}
</style>
